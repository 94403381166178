window.addEventListener('scroll', () => {
  const navbar = document.querySelector('.navBar')
  navbar.classList.toggle('sticky', window.scrollY > 30)
})

const navToggler = document.querySelector('.navBar__toggler')
const navTogglerBurger = document.querySelector('.navBar__toggler-burger')
const navBar = document.querySelector('.navBar__menu')
const navBarLogo = document.querySelector('.navBar__logo')
const navBarLogoSvg = document.querySelectorAll('.navBar__logo-svg')

// ! Adding an event listener to the burger
// so that it can transform into an X and open the menu
navToggler.addEventListener('click', () => {
  navTogglerBurger.classList.toggle('open')
  navBar.classList.toggle('active')
  navBarLogo.classList.toggle('active')
  navBarLogoSvg.forEach(el =>{
    el.classList.toggle('active')
  })
 
  // ! Adding an event listener to the entire menu
  // so that wherever the user clicks both the burger and the menu can be closed

  navBar.addEventListener('mouseup', () => {
    navTogglerBurger.classList.remove('open')
    navBar.classList.remove('active')
  })
})

