// Floating action Logic
const  floaty = document.querySelector('.floaty')
const  floatyListItem = document.querySelectorAll('.floaty-list-item')
const  floatyBtn = document.querySelector('.floaty-btn')

window.addEventListener('scroll', () => {
  floaty.classList.toggle('visible', window.scrollY > 30)
})
floaty.addEventListener('click', ()=>{
  floaty.classList.toggle('is-active')
  floatyListItem.forEach(li =>{
    li.classList.toggle('is-active')
  })
  floatyBtn.classList.toggle('is-active')
})